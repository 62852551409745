import { DiagnosisCodeApi, DiagnosisCodeUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { DiagnosisCodeTypeEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<DiagnosisCodeApi>();

type DiagnosisCodeArgs = Omit<
  ClassProperties<DiagnosisCode>,
  // Omit computed properties based on core model data.
  'isActiveDisplayValue'
>;

export class DiagnosisCode {
  public constructor(props: ClassProperties<DiagnosisCodeArgs>) {
    this.description = props.description;
    this.id = props.id;
    this.isActive = props.isActive;
    this.name = props.name;
    this.type = props.type;

    // Computed properties.
    this.isActiveDisplayValue = this.isActive ? 'Yes' : 'No';
  }

  /**
   * The io-ts codec for runtime type checking of the Diagnosis Code API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.union([io.string, io.null]),
      description: io.union([io.string, io.null]),
      id: io.number,
      isActive: io.boolean,
      type: io.union([
        io.literal(DiagnosisCodeTypeEnum.DSM),
        io.literal(DiagnosisCodeTypeEnum.ICD10),
      ]),
    },
    'DiagnosisCodeApi',
  );

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'code' }) public readonly name: string | null;
  @api({ key: 'type' }) public readonly type: DiagnosisCodeTypeEnum;

  // Computed properties.
  public readonly isActiveDisplayValue: string;

  /**
   * Deserializes a Diagnosis Code object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Diagnosis Code object.
   * @throws An error if the value is not a valid Diagnosis Code object.
   */
  public static deserialize(
    value: NonNullable<DiagnosisCodeApi>,
  ): DiagnosisCode {
    const decoded = decode(DiagnosisCode.Codec, value);
    return new DiagnosisCode({
      description: decoded.description,
      id: decoded.id,
      isActive: decoded.isActive,
      name: decoded.code,
      type: decoded.type,
    });
  }

  /**
   * Deserializes a list of Diagnosis Code objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Diagnosis Code objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Diagnosis Code objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DiagnosisCodeApi>>,
  ): readonly DiagnosisCode[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Diagnosis Code objects.');
    }
    return values.map(DiagnosisCode.deserialize);
  }
}

export class DiagnosisCodeUpdate extends DiagnosisCode {
  public constructor(props: ClassProperties<DiagnosisCodeArgs>) {
    super(props);
  }

  /** Serialize the data to a format that the API accepts. */
  public serialize(): DiagnosisCodeUpdateApi {
    return {
      code: this.name,
      description: this.description,
      id: this.id,
      isActive: this.isActive,
      type: this.type,
    };
  }
}
