import { ClientDiagnosisApi, ClientDiagnosisUpdateApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { DiagnosisPriorityEnum } from 'src/app/enumerators';
import { decode } from 'src/app/utilities';

import {
  DiagnosisProblem,
  DiagnosisProblemUpdate,
} from 'src/app/models/diagnosis/diagnosis-problem.model';
import { Diagnosis } from 'src/app/models/diagnosis/diagnosis.model';
import { Name } from 'src/app/models/user/name.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<ClientDiagnosisApi>();
const apiUpdate = apiDecorator<ClientDiagnosisUpdateApi>();

type ClientDiagnosisArgs = Omit<
  ClassProperties<ClientDiagnosis>,
  // Omit computed properties set on construct from outside data.
  | 'createdDateDisplayValue'
  | 'diagnosisCode'
  | 'diagnosisDescription'
  | 'diagnosisImpression'
  | 'problemDescription'
  | 'resolvedDateFormatted'
>;

abstract class ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisBase>) {
    this.isActive = props.isActive;
    this.isResolved = props.isResolved;
    this.isRuleOut = props.isRuleOut;
    this.resolvedDate = props.resolvedDate;
  }

  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isResolved' }) public readonly isResolved: boolean;
  @api({ key: 'isRuleOut' }) public readonly isRuleOut: boolean;
  @api({ key: 'resolvedDate' }) public readonly resolvedDate: DateTime | null;
}

export class ClientDiagnosis extends ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisArgs>) {
    super(props);

    // Set properties.
    this.createdAt = props.createdAt;
    this.createdBy = props.createdBy;
    this.diagnosis = props.diagnosis;
    this.id = props.id;
    this.priority = props.priority;
    this.problem = props.problem;

    // Computed values.
    this.createdDateDisplayValue = this.createdAt.toFormat('MM/dd/yyyy');
    this.diagnosisCode = this.diagnosis?.code ?? null;
    this.diagnosisDescription = this.diagnosis?.description ?? null;
    this.diagnosisImpression =
      this.diagnosisCode && this.diagnosisDescription
        ? `[${this.diagnosisCode}] ${this.diagnosisDescription}`
        : null;
    this.problemDescription = this.problem?.description ?? null;
  }

  /**
   * The io-ts codec for runtime type checking of the Client Diagnosis API
   * model.
   */
  public static readonly Codec = io.type(
    {
      createdAt: io.string,
      createdBy: UserBase.BaseCodec,
      diagnosis: io.union([Diagnosis.Codec, io.null]),
      id: io.number,
      isActive: io.boolean,
      isResolved: io.boolean,
      isRuleOut: io.boolean,
      priority: io.union([
        io.literal(DiagnosisPriorityEnum.PRIMARY),
        io.literal(DiagnosisPriorityEnum.SECONDARY),
        io.literal(DiagnosisPriorityEnum.TERTIARY),
        io.literal(DiagnosisPriorityEnum.OTHER),
        io.null,
      ]),
      problem: io.union([DiagnosisProblem.Codec, io.null]),
      resolvedDate: io.union([io.string, io.null]),
    },
    'ClientDiagnosisApi',
  );

  @api({ key: 'createdAt' }) public readonly createdAt: DateTime;
  @api({ key: 'createdBy' }) public readonly createdBy: UserBase;
  @api({ key: 'diagnosis' }) public readonly diagnosis: Diagnosis | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'priority' })
  public readonly priority: DiagnosisPriorityEnum | null;
  @api({ key: 'problem' }) public readonly problem: DiagnosisProblem | null;

  // Computed properties
  /** String formatted created date of MM/dd/yyyy */
  public readonly createdDateDisplayValue: string;
  public readonly diagnosisCode: string | null;
  public readonly diagnosisDescription: string | null;
  public readonly diagnosisImpression: string | null;
  public readonly problemDescription: string | null;

  /**
   * Deserializes a Client Diagnosis object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Client Diagnosis object.
   * @throws An error if the value is not a valid Client Diagnosis object.
   */
  public static async deserialize(
    value: NonNullable<ClientDiagnosisApi>,
  ): Promise<ClientDiagnosis> {
    const decoded = decode(ClientDiagnosis.Codec, value);
    return new ClientDiagnosis({
      createdAt: await DateTime.fromISO(
        decoded.createdAt,
      ).toCurrentFacilityTime(),
      createdBy: UserBase.deserialize({
        ...decoded.createdBy,
        name: decoded.createdBy.name
          ? Name.deserialize(decoded.createdBy.name)
          : Name.deserialize({ first: null, last: null }),
      }),
      diagnosis: decoded.diagnosis
        ? Diagnosis.deserialize(decoded.diagnosis)
        : null,
      id: decoded.id,
      isActive: decoded.isActive,
      isResolved: decoded.isResolved,
      isRuleOut: decoded.isRuleOut,
      priority: decoded.priority,
      problem: decoded.problem
        ? DiagnosisProblem.deserialize(decoded.problem)
        : null,
      resolvedDate: decoded.resolvedDate
        ? await DateTime.fromISO(decoded.resolvedDate).toCurrentFacilityTime()
        : null,
    });
  }

  /**
   * Deserializes a list of Client Diagnosis objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Client Diagnosis objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Client Diagnosis
   * objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<ClientDiagnosisApi>>,
  ): Promise<readonly ClientDiagnosis[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Client Diagnosis objects.');
    }
    return Promise.all(values.map(ClientDiagnosis.deserialize));
  }

  /**
   * A pure function that returns the unique identifier for a Client Diagnosis
   * resource. This is specifically used for the `trackBy` function in
   * Angular's `ngFor` directive to improve rendering performance when a list
   * of Clients Diagnosis is rendered.
   */
  public static trackBy(
    _index: number,
    clientDiagnosis: ClientDiagnosis,
  ): number {
    return clientDiagnosis.id;
  }
}

export class ClientDiagnosisUpdate extends ClientDiagnosisBase {
  public constructor(props: ClassProperties<ClientDiagnosisUpdate>) {
    super(props);

    this.diagnosis = props.diagnosis;
    this.priority = props.priority;
    this.problem = props.problem;
  }

  @apiUpdate({ key: 'diagnosis' }) public readonly diagnosis: Diagnosis;
  @apiUpdate({ key: 'priority' })
  public readonly priority: DiagnosisPriorityEnum | null;
  @apiUpdate({ key: 'problem' }) public readonly problem: DiagnosisProblem;

  /**
   * Serialize the Client Diagnosis Update object to an API model.
   *
   * @returns The serialized Client Diagnosis Update object.
   */
  public serialize(): ClientDiagnosisUpdateApi {
    return {
      diagnosis: this.diagnosis,
      isActive: this.isActive,
      isResolved: this.isResolved,
      isRuleOut: this.isRuleOut,
      priority: this.priority,
      priorityRank: 0,
      problem: new DiagnosisProblemUpdate(this.problem).serialize(),
      resolvedDate: this.resolvedDate ? this.resolvedDate.toISO() : null,
    };
  }
}
