import {
  DiagnosisProblemApi,
  DiagnosisProblemCreateApi,
  DiagnosisProblemUpdateApi,
} from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { DiagnosisProblemGroup } from 'src/app/models/diagnosis/diagnosis-problem-group.model';

const api = apiDecorator<DiagnosisProblemApi>();

type DiagnosisProblemArgs = Omit<
  ClassProperties<DiagnosisProblem>,
  // Omit computed properties based on core model data.
  'displayName' | 'isActiveDisplayValue'
>;

export class DiagnosisProblem {
  public constructor(props: ClassProperties<DiagnosisProblemArgs>) {
    this.code = props.code;
    this.description = props.description;
    this.diagnosisNumber = props.diagnosisNumber;
    this.group = props.group;
    this.id = props.id;
    this.isActive = props.isActive;

    // Computed properties
    this.displayName = `${this.description} (${this.group.code})`;
    this.isActiveDisplayValue = this.isActive ? 'Active' : 'Inactive';
  }

  /**
   * The io-ts codec for runtime type checking of the Diagnosis Problem API
   * model.
   */
  public static readonly Codec = io.type(
    {
      code: io.number,
      description: io.union([io.string, io.null]),
      diagnosisNumber: io.number,
      group: DiagnosisProblemGroup.Codec,
      id: io.number,
      isActive: io.boolean,
    },
    'DiagnosisProblemApi',
  );

  @api({ key: 'code' }) public readonly code: number;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'diagnosisNumber' }) public readonly diagnosisNumber: number;
  @api({ key: 'group' }) public readonly group: DiagnosisProblemGroup;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;

  // Computed properties
  public readonly displayName: string;
  public readonly isActiveDisplayValue: string;

  /**
   * Deserializes a Diagnosis Problem object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Diagnosis Problem object object.
   * @throws An error if the value is not a valid Diagnosis Problem object.
   */
  public static deserialize(
    value: NonNullable<DiagnosisProblemApi>,
  ): DiagnosisProblem {
    const decoded = decode(DiagnosisProblem.Codec, value);
    return new DiagnosisProblem({
      code: decoded.code,
      description: decoded.description,
      diagnosisNumber: decoded.diagnosisNumber,
      group: DiagnosisProblemGroup.deserialize(decoded.group),
      id: decoded.id,
      isActive: decoded.isActive,
    });
  }

  /**
   * Deserializes a list of Diagnosis Problem objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Diagnosis Problem objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Diagnosis Problem
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DiagnosisProblemApi>>,
  ): readonly DiagnosisProblem[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Diagnosis Problem objects.');
    }
    return values.map(DiagnosisProblem.deserialize);
  }
}

export class DiagnosisProblemCreate {
  public constructor(props: ClassProperties<DiagnosisProblemCreate>) {
    this.description = props.description;
    this.isActive = props.isActive;
  }

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'isActive' }) public readonly isActive: boolean;

  /** Serialize the data to a format that the API accepts. */
  public serialize(): DiagnosisProblemCreateApi {
    return {
      description: this.description,
      isActive: this.isActive,
    };
  }
}

export class DiagnosisProblemUpdate {
  public constructor(props: ClassProperties<DiagnosisProblemUpdate>) {
    this.description = props.description;
    this.id = props.id;
    this.isActive = props.isActive;
  }

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;

  /** Serialize the data to a format that the API accepts. */
  public serialize(): DiagnosisProblemUpdateApi {
    return {
      description: this.description,
      id: this.id,
      isActive: this.isActive,
    };
  }
}
