import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  startWith,
} from 'rxjs';
import { AuthenticatedUser, Client, Facility } from 'src/app/models';
import {
  isNonEmptyString,
  isNonEmptyValue,
  shareSingleReplay,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector:
    'alleva-menu-top-level-item[currentFacility][currentUser][isCollapsed][selectedClient][topLevelMenuItem]',
  templateUrl: './menu-top-level-item.component.html',
  styleUrls: ['./menu-top-level-item.component.scss'],
})
export class MenuTopLevelItemComponent implements OnInit {
  public constructor(private readonly router: Router) {}

  /** The currently selected facility. Required. */
  @Input() public set currentFacility(value: Facility | null) {
    this.currentFacilitySubject.next(value);
  }
  private readonly currentFacilitySubject = new ReplaySubject<Facility | null>(
    1,
  );
  protected readonly currentFacilityChanges =
    this.currentFacilitySubject.asObservable();

  /** The current logged in user. Required. */
  @Input() public set currentUser(value: AuthenticatedUser | null) {
    this.currentUserSubject.next(value);
  }
  private readonly currentUserSubject =
    new ReplaySubject<AuthenticatedUser | null>(1);
  protected readonly currentUserChanges =
    this.currentUserSubject.asObservable();

  /** Whether the menu item should use a collapsed (mobile) view. */
  @Input() public set isCollapsed(value: BooleanInput) {
    this.isCollapsedSubject.next(coerceBooleanProperty(value));
  }
  private readonly isCollapsedSubject = new ReplaySubject<boolean>(1);
  protected readonly isCollapsedChanges = this.isCollapsedSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** Whether the sub menu is expanded. */
  protected set isSubMenuOpen(value: boolean) {
    this.isSubMenuOpenSubject.next(value);
  }
  private readonly isSubMenuOpenSubject = new BehaviorSubject<boolean>(false);
  protected readonly isSubMenuOpenChanges = this.isSubMenuOpenSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** The currently selected client, if any. */
  @Input() public set selectedClient(value: Client | null) {
    this.selectedClientSubject.next(value);
  }
  private readonly selectedClientSubject = new ReplaySubject<Client | null>(1);
  protected readonly selectedClientChanges = this.selectedClientSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** The menu item to display. Required. */
  @Input() public topLevelMenuItem!: TopLevelMenuItem;

  protected readonly tooltipDisplayChanges = combineLatest([
    this.isCollapsedChanges,
    this.isSubMenuOpenChanges,
  ]).pipe(
    map(([isCollapsed, isSubMenuOpen]) => {
      if (!isCollapsed) {
        return '';
      }

      if (this.topLevelMenuItem.subLevelMenuItems) {
        return (
          (isSubMenuOpen ? 'Collapse ' : 'Expand "') +
          this.topLevelMenuItem.label +
          '" menu.'
        );
      }

      return 'Go to the "' + this.topLevelMenuItem.label + '" page.';
    }),
  );

  protected readonly menuItemLinkChanges = this.currentFacilityChanges.pipe(
    filter(isNonEmptyValue),
    map((facility) => `/facility/${facility.id}`),
    distinctUntilChanged(),
    map((facilityUrl) => {
      const path = this.topLevelMenuItem.path;
      if (!path) {
        return undefined;
      }

      return facilityUrl + path;
    }),
    shareSingleReplay(),
  );

  protected readonly subLevelMenuItemsChanges: Observable<ReadonlyArray<
    SubLevelMenuItem & { isDisplayed: boolean }
  > | null> = combineLatest([
    this.currentFacilityChanges.pipe(filter(isNonEmptyValue)),
    this.currentUserChanges.pipe(filter(isNonEmptyValue)),
  ]).pipe(
    map(
      ([currentFacility, currentUser]) =>
        this.topLevelMenuItem.subLevelMenuItems?.map((subLevelMenuItem) => {
          const isBillingEnabled = currentFacility.features.isBillingEnabled;
          const isInsightsEnabled = currentUser.settings.isInsightsEnabled;
          const isSandboxEnabled = config.features.isSandboxEnabled;

          switch (subLevelMenuItem.path) {
            case '/billing-offsite': {
              if (isBillingEnabled) {
                return {
                  ...subLevelMenuItem,
                  isDisplayed: true,
                };
              }
              break;
            }
            case '/reports/insights': {
              if (isInsightsEnabled) {
                return {
                  ...subLevelMenuItem,
                  isDisplayed: true,
                };
              }
              break;
            }
            case '/sandbox/buttons':
            case '/sandbox/editor':
            case '/sandbox/forms':
            case '/sandbox/icons':
            case '/sandbox/loading-indicators':
            case '/sandbox/messages':
            case '/sandbox/print':
            case '/sandbox/signatures':
            case '/sandbox/tables': {
              return {
                ...subLevelMenuItem,
                isDisplayed: isSandboxEnabled,
              };
            }
            case '/settings/billing':
            case '/settings/billing/billing-codes':
            case '/settings/billing/insurance-companies':
            case '/settings/billing/modifiers':
            case '/settings/billing/rev-codes':
            case '/settings/billing/rules': {
              return {
                ...subLevelMenuItem,
                isDisplayed: true,
              };
            }
          }

          // Handle cases for admins.
          if (currentUser.role.isAdmin) {
            return {
              ...subLevelMenuItem,
              isDisplayed: true,
            };
          }

          const hasPermission = currentUser.hasPermission(
            subLevelMenuItem.permissions,
          );

          return {
            ...subLevelMenuItem,
            isDisplayed: hasPermission,
          };
        }) || null,
    ),
    shareSingleReplay(),
  );

  public ngOnInit(): void {
    // Close the menu when the user navigates to a new page _AND_ open the
    // menu if the user navigates to a page that is a child of the current page.
    combineLatest([
      this.router.events.pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
        startWith({ url: this.router.url }),
      ),
      this.currentFacilityChanges.pipe(
        filter(isNonEmptyValue),
        map((facility) => `/facility/${facility.id}`),
        distinctUntilChanged(),
      ),
      this.selectedClientChanges,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([event, currentFacilityUrl, selectedClient]) => {
        let hasActiveSubLevelMenuItem = false;

        if (this.topLevelMenuItem.isClientMenu && selectedClient) {
          hasActiveSubLevelMenuItem =
            this.topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                currentFacilityUrl +
                  subLevelMenuItem.path?.replace(
                    ':id',
                    selectedClient.id.toString(),
                  ) ===
                event.url,
            ) ?? false;
        } else {
          hasActiveSubLevelMenuItem =
            this.topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) => {
                const menuItemUrl = currentFacilityUrl + subLevelMenuItem.path;

                // Handle Billing Settings nested (tabbed) relative pages.
                const billingRoute: PageRoute = '/settings/billing';
                if (subLevelMenuItem.path.startsWith(billingRoute)) {
                  return event.url.includes('/settings/billing/');
                }

                // Handle Treatment Plan nested (tabbed) relative pages.
                const treatmentPlanRoute: PageRoute =
                  '/settings/treatment-plan';
                if (subLevelMenuItem.path.startsWith(treatmentPlanRoute)) {
                  return event.url.includes('/settings/treatment-plan/');
                }

                return menuItemUrl === event.url;
              },
            ) ?? false;
        }

        this.isSubMenuOpen = hasActiveSubLevelMenuItem;
      });
  }

  protected getClientName(client: Client): string {
    const { name } = client;
    return isNonEmptyString(name.first)
      ? name.first.charAt(0) + '. ' + (name.last || 'N/A')
      : (name.last ?? 'N/A');
  }

  protected async toggleSubMenu(): Promise<void> {
    const isSubMenuOpen = await firstValueFrom(this.isSubMenuOpenChanges);
    this.isSubMenuOpen = !isSubMenuOpen;
  }
}
