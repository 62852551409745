import { BillingRuleApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { DailyDropCriteriaEnum } from 'src/app/enumerators';
import { decode, isNonEmptyValue } from 'src/app/utilities';

import { BillingCode } from 'src/app/models/billing/billing-code.model';
import { BillingRuleClaim } from 'src/app/models/billing/billing-rule-claim.model';
import { BillingRuleDuration } from 'src/app/models/billing/billing-rule-duration.model';
import { BillingRuleService } from 'src/app/models/billing/billing-rule-service.model';
import { RevenueCode } from 'src/app/models/billing/revenue-code.model';
import { BillingRuleRate } from 'src/app/models/billing/rule-rate/billing-rule-rate.model';
import { FacilityBase } from 'src/app/models/facility/facility.model';
import { InsuranceCompany } from 'src/app/models/insurance/insurance-company.model';
import { LevelOfCare } from 'src/app/models/level-of-care.model';
import { License } from 'src/app/models/license.model';
import { UserBase } from 'src/app/models/user/user.model';

const api = apiDecorator<BillingRuleApi>();

type BillingRuleArgs = Omit<
  ClassProperties<BillingRule>,
  // Omit computed properties based on core model data.
  | 'facilityNamesDisplayValue'
  | 'isBaselineRuleDisplayValue'
  | 'isDailyDropDisplayValue'
  | 'modifierCodesDisplayValue'
  | 'providerCredentialsDisplayValue'
  | 'providerNamesDisplayValue'
  | 'ratesDisplayValue'
  | 'servicesDisplayValue'
  | 'valueCodesDisplayValue'
>;

export class BillingRule {
  public constructor(props: ClassProperties<BillingRuleArgs>) {
    this.billingCode = props.billingCode;
    this.billingCodeSuffix = props.billingCodeSuffix;
    this.claim = props.claim;
    this.dailyDropCriteria = props.dailyDropCriteria;
    this.duration = props.duration;
    this.effectiveFrom = props.effectiveFrom;
    this.effectiveTo = props.effectiveTo;
    this.facilities = props.facilities;
    this.id = props.id;
    this.isActive = props.isActive;
    this.isBaselineRule = props.isBaselineRule;
    this.isDailyDrop = props.isDailyDrop;
    this.levelOfCare = props.levelOfCare;
    this.modifiedAt = props.modifiedAt;
    this.name = props.name;
    this.payor = props.payor;
    this.providerCredentials = props.providerCredentials;
    this.providers = props.providers;
    this.rates = props.rates;
    this.renderingProvider = props.renderingProvider;
    this.revenueCode = props.revenueCode;
    this.services = props.services;

    // Computed values
    this.facilityNamesDisplayValue =
      this.facilities && this.facilities.length > 0
        ? this.facilities.map((facility) => facility.name).join(', ')
        : null;

    this.isBaselineRuleDisplayValue = props.isBaselineRule ? 'Yes' : 'No';
    this.isDailyDropDisplayValue = props.isDailyDrop ? 'Yes' : 'No';

    this.modifierCodesDisplayValue =
      this.claim.modifiers && this.claim.modifiers.length > 0
        ? this.claim.modifiers.map((modifier) => modifier.code).join(', ')
        : null;

    this.providerCredentialsDisplayValue =
      this.providerCredentials && this.providerCredentials.length > 0
        ? this.providerCredentials
            .map((providerCredentials) => providerCredentials.name)
            .join(', ')
        : null;

    this.providerNamesDisplayValue =
      this.providers && this.providers.length > 0
        ? this.providers.map((provider) => provider.fullName).join(', ')
        : null;

    // Get the supported locale for currency formatting.
    const currencyFormatter = new Intl.NumberFormat('en-us', {
      // Future: Make dynamic based on the facility unit of measurement.
      currency: 'USD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    });

    const currentRate = this.rates?.find((rate) => rate.isCurrent);
    this.ratesDisplayValue = currentRate
      ? currencyFormatter.format(currentRate.unitRate)
      : null;

    if (this.services && this.services.details.length > 0) {
      this.servicesDisplayValue = this.services.details
        .map((detail) => {
          if (detail.facility === null) {
            return detail.name;
          }
          return `${detail.name} (${detail.facility.name})`;
        })
        .join(', ');
    } else {
      this.servicesDisplayValue = null;
    }

    // Get the filtered value codes
    const filteredValueCodes = this.claim.valueCodes
      ? this.claim.valueCodes
          .map(({ code }) => code?.id)
          .filter(isNonEmptyValue)
      : [];

    // Set the display value based on the length of the filtered array
    this.valueCodesDisplayValue =
      filteredValueCodes.length > 0 ? filteredValueCodes.join(', ') : '—';
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rules
   * API model.
   */
  public static readonly Codec = io.type(
    {
      billingCode: io.union([BillingCode.Codec, io.null]),
      billingCodeSuffix: io.union([io.string, io.null]),
      claim: BillingRuleClaim.Codec,
      dailyDropCriteria: io.union([
        io.literal(DailyDropCriteriaEnum.AUTH_HRS_MET_EXCLUDE_INTAKE),
        io.literal(DailyDropCriteriaEnum.AUTH_HRS_MET_GROUP_SES_ONLY),
        io.literal(DailyDropCriteriaEnum.AUTH_HRS_MET_INCLUDE_INTAKE),
        io.literal(DailyDropCriteriaEnum.NO_CRITERIA),
        io.null,
      ]),
      duration: io.union([BillingRuleDuration.Codec, io.null]),
      effectiveFrom: io.string,
      effectiveTo: io.union([io.string, io.null]),
      facilities: io.union([io.array(FacilityBase.BaseCodec), io.null]),
      id: io.number,
      isActive: io.boolean,
      isBaselineRule: io.boolean,
      isDailyDrop: io.boolean,
      levelOfCare: LevelOfCare.Codec,
      modifiedAt: io.string,
      name: io.string,
      payor: io.union([InsuranceCompany.Codec, io.null]),
      providerCredentials: io.union([io.array(License.Codec), io.null]),
      providers: io.union([io.array(UserBase.BaseCodec), io.null]),
      rates: io.array(BillingRuleRate.Codec),
      renderingProvider: io.union([UserBase.BaseCodec, io.null]),
      revenueCode: io.union([RevenueCode.Codec, io.null]),
      services: io.union([BillingRuleService.Codec, io.null]),
    },
    'BillingRuleApi',
  );

  @api({ key: 'billingCode' }) public readonly billingCode: BillingCode | null;
  @api({ key: 'billingCodeSuffix' }) public readonly billingCodeSuffix:
    | string
    | null;
  @api({ key: 'claim' }) public readonly claim: BillingRuleClaim;
  @api({ key: 'dailyDropCriteria' })
  public readonly dailyDropCriteria: DailyDropCriteriaEnum | null;
  @api({ key: 'duration' })
  public readonly duration: BillingRuleDuration | null;
  @api({ key: 'effectiveFrom' }) public readonly effectiveFrom: DateTime;
  @api({ key: 'effectiveTo' }) public readonly effectiveTo: DateTime | null;
  @api({ key: 'facilities' }) public readonly facilities:
    | readonly FacilityBase[]
    | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'isBaselineRule' }) public readonly isBaselineRule: boolean;
  @api({ key: 'isDailyDrop' }) public readonly isDailyDrop: boolean;
  @api({ key: 'levelOfCare' }) public readonly levelOfCare: LevelOfCare;
  @api({ key: 'modifiedAt' }) public readonly modifiedAt: DateTime;
  @api({ key: 'name' }) public readonly name: string;
  @api({ key: 'payor' }) public readonly payor: InsuranceCompany | null;
  @api({ key: 'providerCredentials' }) public readonly providerCredentials:
    | readonly License[]
    | null;
  @api({ key: 'providers' }) public readonly providers:
    | readonly UserBase[]
    | null;
  @api({ key: 'rates' }) public readonly rates: readonly BillingRuleRate[];
  @api({ key: 'renderingProvider' })
  public readonly renderingProvider: UserBase | null;
  @api({ key: 'revenueCode' }) public readonly revenueCode: RevenueCode | null;
  @api({ key: 'services' })
  public readonly services: BillingRuleService | null;

  // Computed values
  public readonly facilityNamesDisplayValue: string | null;
  public readonly isBaselineRuleDisplayValue: 'Yes' | 'No';
  public readonly isDailyDropDisplayValue: 'Yes' | 'No';
  public readonly modifierCodesDisplayValue: string | null;
  public readonly providerCredentialsDisplayValue: string | null;
  public readonly providerNamesDisplayValue: string | null;
  public readonly ratesDisplayValue: string | null;
  public readonly servicesDisplayValue: string | null;
  public readonly valueCodesDisplayValue: string | null;

  /**
   * Deserializes a Billing Payor Rules object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rules object.
   * @throws An error if the value is not a valid Billing Payor Rules object.
   */
  public static async deserialize(
    value: NonNullable<BillingRuleApi>,
  ): Promise<BillingRule> {
    const decoded = decode(BillingRule.Codec, value);
    return new BillingRule({
      billingCode: decoded.billingCode
        ? BillingCode.deserialize(decoded.billingCode)
        : null,
      billingCodeSuffix: decoded.billingCodeSuffix,
      claim: BillingRuleClaim.deserialize(decoded.claim),
      dailyDropCriteria: decoded.dailyDropCriteria,
      duration: decoded.duration
        ? BillingRuleDuration.deserialize(decoded.duration)
        : null,
      effectiveFrom: await DateTime.fromISO(
        decoded.effectiveFrom,
      ).toCurrentFacilityTime(),
      effectiveTo: decoded.effectiveTo
        ? await DateTime.fromISO(decoded.effectiveTo).toCurrentFacilityTime()
        : null,
      facilities: decoded.facilities
        ? FacilityBase.deserializeList(decoded.facilities)
        : null,
      id: decoded.id,
      isActive: decoded.isActive,
      isBaselineRule: decoded.isBaselineRule,
      isDailyDrop: decoded.isDailyDrop,
      levelOfCare: LevelOfCare.deserialize(decoded.levelOfCare),
      modifiedAt: await DateTime.fromISO(
        decoded.modifiedAt,
      ).toCurrentFacilityTime(),
      name: decoded.name,
      payor: decoded.payor ? InsuranceCompany.deserialize(decoded.payor) : null,
      providerCredentials: decoded.providerCredentials
        ? License.deserializeList(decoded.providerCredentials)
        : null,
      providers: decoded.providers
        ? UserBase.deserializeList(decoded.providers)
        : null,
      rates: await BillingRuleRate.deserializeList(decoded.rates),
      renderingProvider: decoded.renderingProvider
        ? UserBase.deserialize(decoded.renderingProvider)
        : null,
      revenueCode: decoded.revenueCode
        ? RevenueCode.deserialize(decoded.revenueCode)
        : null,
      services: decoded.services
        ? BillingRuleService.deserialize(decoded.services)
        : null,
    });
  }

  /**
   * Deserializes a list of Billing Payor Rules objects from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized list of Billing Payor Rules objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not Billing Payor Rules objects.
   */
  public static async deserializeList(
    values: ReadonlyArray<NonNullable<BillingRuleApi>>,
  ): Promise<readonly BillingRule[]> {
    if (!Array.isArray(values)) {
      throw new Error('Expected an array of Billing Payor Rules objects');
    }
    return Promise.all(values.map(BillingRule.deserialize));
  }
}
