import { isNonEmptyValue, replaceNumberGroups } from 'src/app/utilities';

import { HttpParams } from '@angular/common/http';

/**
 * Parse our custom request parameter interface into a format that can be used
 * by the HTTP client and consumed by the API.
 *
 * @param requestParameters The request parameters to parse.
 * @returns The fully parsed HTTP parameters.
 */
export function parseHttpParams(
  requestParams: readonly RequestParameter[] | null | undefined,
): HttpParams | undefined {
  if (!isNonEmptyValue(requestParams) || requestParams.length === 0) {
    return undefined;
  }

  return requestParams.reduce(
    (requestParam, { key, value }) =>
      requestParam.append(key, value?.toString() ?? 'null'),
    new HttpParams(),
  );
}

export function parseNumber(value: unknown): number | undefined {
  switch (typeof value) {
    case 'number': {
      return value;
    }
    case 'string': {
      const parsedValue = Number(value);
      return isNaN(parsedValue) ? undefined : parsedValue;
    }
    case 'boolean': {
      return value ? 1 : 0;
    }
    case 'object':
    default: {
      return undefined;
    }
  }
}

/**
 * Replace all numbers with `:id` to match the pre-defined page routes.
 *
 * @param value The string url to parse.
 * @returns The parsed url route.
 */
export function parseRouteParams(value: string): string {
  const id: RouteResourceIdentifier = ':id';
  return replaceNumberGroups(value, id);
}
