import { ClientDiagnosisApi, DiagnosisApi } from 'api/models';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { DiagnosisOrderByEnum } from 'src/app/enumerators';
import {
  Client,
  ClientDiagnosis,
  ClientDiagnosisUpdate,
  Diagnosis,
} from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the diagnosis API.
 */
@Injectable()
export class ClientDiagnosisService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return data for all diagnosis.
   *
   * @param requestParameters Optional parameters to filter the results.
   * @returns All diagnosis on success, undefined on error.
   */
  public getAll(
    requestParameters?: readonly DiagnosisListParam[],
  ): Observable<readonly Diagnosis[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly DiagnosisApi[] | undefined>(`${config.api.url}/diagnosis`, {
        params,
      })
      .pipe(
        map((response) =>
          response ? Diagnosis.deserializeList(response) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all diagnosis data for the given client.
   *
   * @param clientId The client ID to get the diagnosis list for.
   * @param requestParams Optional parameters to filter the results.
   * @returns All ClientDiagnosis model data on success, undefined on error.
   */
  public getAllByClient(
    clientId: Client['id'],
    requestParams?: readonly DiagnosisListParam[],
  ): Observable<readonly ClientDiagnosis[] | undefined> {
    const params = parseHttpParams(requestParams);
    return this.httpClient
      .get<
        readonly ClientDiagnosisApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/diagnosis`, { params })
      .pipe(
        switchMap((response) =>
          response ? ClientDiagnosis.deserializeList(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update the diagnosis for the given client.
   *
   * @param clientId The client ID to update the diagnosis for.
   * @param clientDiagnosisId The ID of the client diagnosis to update.
   * @param clientDiagnosisUpdate The client diagnosis update data.
   * @returns The updated client diagnosis on success, undefined on error.
   */
  public patch(
    clientId: Client['id'],
    clientDiagnosisId: ClientDiagnosis['id'],
    clientDiagnosisUpdate: ClientDiagnosisUpdate,
  ): Observable<ClientDiagnosis | undefined> {
    return this.httpClient
      .patch<
        ClientDiagnosisApi | undefined
      >(`${config.api.url}/clients/${clientId}/diagnosis/${clientDiagnosisId}`, clientDiagnosisUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? ClientDiagnosis.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a diagnosis for the given client.
   *
   * @param clientId The client ID to create the diagnosis for.
   * @param clientDiagnosisUpdate The client diagnosis update data.
   * @returns The new client diagnosis data on success, undefined on error.
   */
  public post(
    clientId: Client['id'],
    clientDiagnosisUpdate: ClientDiagnosisUpdate,
  ): Observable<ClientDiagnosis | undefined> {
    return this.httpClient
      .post<
        ClientDiagnosisApi | undefined
      >(`${config.api.url}/clients/${clientId}/diagnosis`, clientDiagnosisUpdate.serialize())
      .pipe(
        switchMap((response) =>
          response ? ClientDiagnosis.deserialize(response) : of(undefined),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Delete a diagnosis for the given client.
   *
   * @param clientId The client id to delete the diagnosis from.
   * @param clientDiagnosisId The id of the diagnosis to delete from the
   * client.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientDiagnosisId: ClientDiagnosis['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/diagnosis/${clientDiagnosisId}`, { observe: 'response' })
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Diagnosis API.
 */
export interface DiagnosisListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'name' | 'order' | 'sort';
  /** The value to use for the query parameter. */
  value: string | DiagnosisOrderByEnum;
}
