import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import {
  ApplicationRef,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  ProviderToken,
  Type,
  createComponent,
} from '@angular/core';

import { DialogConfig } from 'src/app/components/dialogs/dialog-config';
import { DialogRef } from 'src/app/components/dialogs/dialog-ref';
import { DialogComponent } from 'src/app/components/dialogs/dialog.component';
import { DialogModule } from 'src/app/components/dialogs/dialog.module';

@UntilDestroy()
@Injectable({ providedIn: DialogModule })
export class DialogService {
  public constructor(
    private readonly applicationRef: ApplicationRef,
    private readonly injector: Injector,
  ) {}

  private dialogComponentRef!: ComponentRef<
    DialogComponent<unknown, unknown, unknown>
  >;

  /**
   * Opens a dialog with the specified component.
   *
   * C = The type of the component to open.
   * D = The type of the data to pass to the component.
   * R = The type of the result to return from the dialog.
   *
   * @param component The component to open.
   * @param config The configuration for the dialog.
   * @returns A dialog reference.
   */
  public open<C, D, R>(
    component: Type<C>,
    config: DialogConfig<D>,
  ): DialogRef<R> {
    // Set up the injection tokens for the dialog component. We'll do the
    // dialog data first for use by the injected component we provide.
    const map = new WeakMap();
    map.set(DialogConfig, config);

    // Then we'll do the dialog reference which we can use to hold reference to
    // our dialog so we can close it and return a result.
    const dialogRef = new DialogRef<R>();
    map.set(DialogRef, dialogRef);

    // Create the component instance in memory for use.
    const dialogComponentRef = createComponent(DialogComponent, {
      elementInjector: new DialogInjector(this.injector, map),
      environmentInjector: this.applicationRef.injector,
    });
    // Attach the component to the DOM.
    this.applicationRef.attachView(dialogComponentRef.hostView);
    const domElem = (dialogComponentRef.hostView as EmbeddedViewRef<unknown>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    // Hold a reference to the component for use later (ie unsubscribe).
    this.dialogComponentRef = dialogComponentRef;

    // Set the component to be injected into the dialog component.
    this.dialogComponentRef.instance.component = component;

    // Destroy everything when the parent dialog or the child dialog component
    // are destroyed.
    dialogRef.afterClosedChanges
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.dialogComponentRef.destroy();
        this.destroy();
      });
    this.dialogComponentRef.instance.onCloseChanges
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        this.dialogComponentRef.destroy();
        this.destroy();
      });

    // Return the dialog reference for use.
    return dialogRef;
  }

  private destroy(): void {
    this.applicationRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}

/**
 * An injector that provides the dialog config and dialog reference to the
 * dialog component.
 */
class DialogInjector implements Injector {
  public constructor(
    private readonly injector: Injector,
    private readonly tokens: WeakMap<object, unknown>,
  ) {}

  public get(
    providerToken: ProviderToken<unknown>,
    notFoundValue?: unknown,
  ): unknown {
    const value = this.tokens.get(providerToken);
    if (value) {
      return value;
    }

    return this.injector.get<unknown>(providerToken, notFoundValue);
  }
}
