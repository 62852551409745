import { DiagnosisProblemApi, DiagnosisProblemPagedListApi } from 'api/models';
import { DiagnosisProblemGroupApi } from 'api/models/diagnosis-problem-group-api.model';
import { Observable, catchError, map, of } from 'rxjs';
import { DiagnosisProblemOrderByEnum } from 'src/app/enumerators';
import {
  Diagnosis,
  DiagnosisProblem,
  DiagnosisProblemCreate,
  DiagnosisProblemGroup,
  DiagnosisProblemUpdate,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/** A service for interacting with the Client Treatment Plan Problems API. */
@Injectable()
export class DiagnosisProblemsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all treatment plan related Problem data.
   *
   * @param requestParameters The list of query parameters to use for the paged
   * request.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The Problem list on success, undefined on error.
   */
  public getPagedList(
    requestParameters: readonly ProblemPagedListParam[],
  ): Observable<PagedList<DiagnosisProblem> | undefined> {
    // If both 'active' true and false are provided, remove the 'active' key.
    if (
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === true,
      ) &&
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === false,
      )
    ) {
      requestParameters = requestParameters.filter(
        (param) => param.key !== 'active',
      );
    }
    return this.httpClient
      .get<DiagnosisProblemPagedListApi | undefined>(
        `${config.api.url}/diagnosis-problems`,
        {
          params: parseHttpParams(requestParameters),
        },
      )
      .pipe(
        map((response) => {
          if (response === undefined) {
            return undefined;
          }
          const pagedList: PagedList<DiagnosisProblem> = {
            ...decode(ProblemPagedListCodec, response),
            results: DiagnosisProblem.deserializeList(response.results),
          };
          return pagedList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all Problem Group data.
   *
   * @returns The Problem Groups on success, undefined on error.
   */
  public getGroups(): Observable<readonly DiagnosisProblemGroup[] | undefined> {
    return this.httpClient
      .get<
        readonly DiagnosisProblemGroupApi[]
      >(`${config.api.url}/diagnosis-problems/groups`)
      .pipe(
        map((response) =>
          response
            ? DiagnosisProblemGroup.deserializeList(response)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new Problem resource.
   *
   * @param problemCreate The Problem value to post.
   * @returns The new Problem record, or undefined on error.
   */
  public post(
    problem: DiagnosisProblemCreate,
  ): Observable<DiagnosisProblem | HttpErrorResponseApi> {
    return this.httpClient
      .post<
        DiagnosisProblemApi | undefined
      >(`${config.api.url}/diagnosis-problems`, problem.serialize(), { observe: 'response' })
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Problem POST response from API is empty!');
          }
          return DiagnosisProblem.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }

  /**
   * Update a Problem resource.
   *
   * @param problemId The ID of the Problem to update.
   * @param problemUpdate The updated Problem data.
   * @returns The updated Problem on success, an HttpErrorResponseApi on error.
   */
  public put(
    problemId: DiagnosisProblem['id'],
    problemUpdate: DiagnosisProblemUpdate,
  ): Observable<DiagnosisProblem | HttpErrorResponseApi> {
    return this.httpClient
      .put<
        DiagnosisProblemApi | undefined
      >(`${config.api.url}/diagnosis-problems/${problemId}`, problemUpdate.serialize(), { observe: 'response' })
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Problem PUT response from API is empty!');
          }
          return DiagnosisProblem.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const ProblemPagedListCodec = PagedListCodec(
  DiagnosisProblem.Codec,
  'ProblemPagedListApi',
);

export interface ProblemPagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'order' | 'pageNumber' | 'pageSize' | 'search';
  /** The value to use for the query parameter. */
  value: boolean | string | number;
}

/**
 * Request parameter interface for use with the Diagnosis Problems API.
 */
export interface DiagnosisProblemListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'diagnosisId' | 'order';
  /** The value to use for the query parameter. */
  value: Diagnosis['id'] | DiagnosisProblemOrderByEnum;
}
