import { DiagnosisCodeApi, DiagnosisCodePagedListApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { DiagnosisCode, DiagnosisCodeUpdate } from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Treatment Plan Diagnosis Codes
 * API.
 */
@Injectable()
export class TreatmentPlanDiagnosisCodesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return all treatment plan related Diagnosis Code data.
   *
   * @param requestParameters The list of query parameters to use for the paged
   * request.
   * @returns The Diagnosis Code list on success, undefined on error.
   */
  public getPagedList(
    requestParameters: readonly DiagnosisCodePagedListParam[],
  ): Observable<PagedList<DiagnosisCode> | undefined> {
    // If both 'active' true and false are provided, remove the 'active' key.
    if (
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === true,
      ) &&
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === false,
      )
    ) {
      requestParameters = requestParameters.filter(
        (param) => param.key !== 'active',
      );
    }
    return this.httpClient
      .get<DiagnosisCodePagedListApi | undefined>(
        `${config.api.url}/diagnosis-codes`,
        {
          params: parseHttpParams(requestParameters),
        },
      )
      .pipe(
        map((response) => {
          if (response === undefined) {
            return undefined;
          }
          const pagedList: PagedList<DiagnosisCode> = {
            ...decode(DiagnosisCodePagedListCodec, response),
            results: DiagnosisCode.deserializeList(response.results),
          };
          return pagedList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a Diagnosis Code resource.
   *
   * @param diagnosisCodeId The ID of the Diagnosis Code to update.
   * @param diagnosisCodeUpdate The updated Diagnosis Code data.
   * @returns The updated Diagnosis Code on success, an HttpErrorResponseApi on error.
   */
  public put(
    diagnosisCodeId: DiagnosisCode['id'],
    diagnosisCodeUpdate: DiagnosisCodeUpdate,
  ): Observable<DiagnosisCode | HttpErrorResponseApi> {
    return this.httpClient
      .put<
        DiagnosisCodeApi | undefined
      >(`${config.api.url}/diagnosis-codes/${diagnosisCodeId}`, diagnosisCodeUpdate.serialize(), { observe: 'response' })
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Diagnosis Code PUT response from API is empty!');
          }
          return DiagnosisCode.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const DiagnosisCodePagedListCodec = PagedListCodec(
  DiagnosisCode.Codec,
  'DiagnosisCodePagedListApi',
);

export interface DiagnosisCodePagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'order' | 'pageNumber' | 'pageSize' | 'search';
  /** The value to use for the query parameter. */
  value: boolean | string | number;
}
