import { DiagnosisProblemGroupApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<DiagnosisProblemGroupApi>();

export class DiagnosisProblemGroup {
  public constructor(props: ClassProperties<DiagnosisProblemGroup>) {
    this.code = props.code;
    this.description = props.description;
    this.id = props.id;
    this.isActive = props.isActive;
  }

  /**
   * The io-ts codec for runtime type checking of the Problem Group API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.number,
      description: io.union([io.string, io.null]),
      id: io.number,
      isActive: io.boolean,
    },
    'DiagnosisProblemGroupApi',
  );

  @api({ key: 'code' }) public readonly code: number;
  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;

  /**
   * Deserializes a Problem Group object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Problem Group object.
   * @throws An error if the value is not a valid Problem Group object.
   */
  public static deserialize(
    value: NonNullable<DiagnosisProblemGroupApi>,
  ): DiagnosisProblemGroup {
    const decoded = decode(DiagnosisProblemGroup.Codec, value);
    return new DiagnosisProblemGroup({
      code: decoded.code,
      description: decoded.description,
      id: decoded.id,
      isActive: decoded.isActive,
    });
  }

  /**
   * Deserializes a list of Problem Group objects from an API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Problem Group objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Problem Group objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DiagnosisProblemGroupApi>>,
  ): readonly DiagnosisProblemGroup[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Problem Group objects.');
    }
    return values.map(DiagnosisProblemGroup.deserialize);
  }
}
