import { Permissions } from 'src/app/constants/permissions';

/** These records are used to tie the page routes to permissions. */
export const PAGE_PERMISSIONS: Record<PageRoute, Permissions> = {
  '/alumni': ['view_alumni_screen'],
  '/billing-offsite': [],
  '/calendar/historical-events': ['view_historical_events_screen'],
  '/calendar/medical-provider-scheduler': ['view_medical_provider_screen'],
  '/calendar/scheduler': ['view_scheduler_screen'],
  '/chef-dashboard': ['view_chef_dashboard_screen'],
  '/clients/:id/admission-screening': [
    'view_manage_admission_checklist_screen',
  ],
  '/clients/:id/billing-intake': ['view_billing_settings'],
  '/clients/:id/biopsychosocial-intake': ['view_manage_intake_screen'],
  '/clients/:id/calendar': ['view_my_calender_screen'],
  '/clients/:id/discharge-planning': ['view_client_discharge_screen'],
  '/clients/:id/doctors-orders': ['view_doctors_orders_screen'],
  '/clients/:id/document-manager': ['view_forms_manager_screen'],
  '/clients/:id/edit': ['view_manage_intake_screen'],
  '/clients/:id/face-to-face': ['view_client_chart_screen'],
  '/clients/:id/homework': ['view_homework_screen'],
  '/clients/:id/meal-plan': ['view_meal_plan_screen'],
  '/clients/:id/medical-intake': ['view_medical_provider_screen'],
  '/clients/:id/medication-administration-record': ['view_client_mar_screen'],
  '/clients/:id/medication': ['view_medication_screen'],
  '/clients/:id/notes': ['view_notes_screen'],
  '/clients/:id/rec-therapy': ['view_group_therapy_screen'],
  '/clients/:id/treatment-plan': ['view_treatment_plan_screen'],
  '/clients/:id': ['view_client_chart_screen'],
  '/clients/list': ['view_client_list_screen'],
  '/dashboard': [],
  '/doctors-orders': ['view_doctors_orders_screen'],
  '/encounter-transmission': ['view_encounter_transmission_screen'],
  '/forbidden': [],
  '/incidents': ['view_incident_report_screen'],
  '/lab-orders': ['view_lab_orders_screen'],
  '/medications/count': ['view_meds_count_screen'],
  '/medications/destruction': ['view_medication_destruction_screen'],
  '/medications/management': ['view_meds_management_screen'],
  '/not-found': [],
  '/notifications': ['view_alerts_notifications_screen'],
  '/occupancy': ['view_occupancy_screen'],
  '/progress-notes': ['view_notes_screen'],
  '/prospective/add': ['view_quick_intake_screen'],
  '/prospective/list': ['view_prospective_client_list_screen'],
  '/referrals': ['view_referrals_screen'],
  '/reports/discharge-reports': ['view_client_reports_screen'],
  '/reports/insights': [],
  '/reports/meds-count-reports': ['view_meds_count_report_screen'],
  '/restricted-access': [],
  '/review-screen/notes-audit': ['view_notes_audit_screen'],
  '/review-screen/notes-review': ['view_notes_review_screen'],
  '/review-screen/signature-review': ['view_signature_review_screen'],
  '/sandbox/buttons': [],
  '/sandbox/editor': [],
  '/sandbox/forms': [],
  '/sandbox/icons': [],
  '/sandbox/loading-indicators': [],
  '/sandbox/messages': [],
  '/sandbox/print': [],
  '/sandbox/signatures': [],
  '/sandbox/tables': [],
  '/settings/admission-checklist': ['view_manage_admission_checklist_screen'],
  '/settings/alerts-frequency': ['view_alerts_frequency_screen'],
  '/settings/alerts-permission': ['view_alerts_notifications_screen'],
  '/settings/alerts-template': ['view_alerts_template_screen'],
  '/settings/alleva-ai': ['view_alleva_ai_menu'],
  '/settings/beds': ['view_bed_management_screen'],
  '/settings/billing': ['view_billing_settings'],
  '/settings/billing/billing-codes': ['view_billing_settings'],
  '/settings/billing/rules': ['view_billing_settings'],
  '/settings/billing/rules/add': ['edit_billing_settings'],
  '/settings/billing/rules/:id/copy': ['edit_billing_settings'],
  '/settings/billing/rules/:id/edit': ['edit_billing_settings'],
  '/settings/billing/insurance-companies': ['view_billing_settings'],
  '/settings/billing/modifiers': ['view_billing_settings'],
  '/settings/billing/rev-codes': ['view_billing_settings'],
  '/settings/client-transfer': ['view_transferred_client_list_screen'],
  '/settings/company-information': ['view_manage_rehab_screen'],
  '/settings/daily-questionnaire': ['view_daily_questionnaire_screen'],
  '/settings/dietary': ['view_manage_meals_screen'],
  '/settings/digital-dashboard': ['view_digital_dashboard_screen'],
  '/settings/discharge': ['view_manage_discharge_screen'],
  '/settings/family-portal': ['view_family_portal_screen'],
  '/settings/forms': ['view_custom_form_screen'],
  '/settings/forms-two': ['view_custom_forms_2_screen'],
  '/settings/advanced-forms': ['view_forms_manager_3.0_screen'],
  '/settings/groups': ['view_manage_groups_screen'],
  '/settings/intakes': ['view_manage_intake_screen'],
  '/settings/labs': ['view_manage_labs_screen'],
  '/settings/master-dropdowns': ['view_system_settings_screen'],
  '/settings/medical-providers': ['view_external_providers_screen'],
  '/settings/office-templates': ['view_custom_tp_template_screen'],
  '/settings/orders': ['view_order_settings_screen'],
  '/settings/pharmacies': ['view_pharmacy_screen'],
  '/settings/profile': ['view_user_management_screen'],
  '/settings/roles': ['view_manage_roles_screen'],
  '/settings/surveys': ['view_survey_monkey_screen'],
  '/settings/templates': ['view_templates_screen'],
  /** @todo - Verify permission */
  '/settings/treatment-plan': ['view_treatment_plan_screen'],
  '/settings/treatment-plan/diagnosis-codes': ['view_treatment_plan_screen'],
  '/settings/treatment-plan/problems': ['view_treatment_plan_screen'],
  '/settings/treatment-plan-review': ['view_manage_tpr_screen'],
  '/settings/users': ['view_user_management_screen'],
  '/shifts/shift-notes': ['view_shift_end_notes_screen'],
  '/shifts/shift-rounds': ['view_shift_rounds_screen'],
  '/utilization-review': ['view_utilization_review_screen'],
};
